import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";

import "./assets/css/reset.less";
import "lib-flexible";
import "./assets/font/iconfont.css";
import "./assets/fonts/iconfont.css";

import { BrowserRouter as Router } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Router>
    <App></App>
  </Router>
);
