import { Navigate } from "react-router-dom";

const Auth = (props) => {
  if (sessionStorage.getItem("openid")) {
    return props.children;
  } else {
    return <Navigate to="/login" replace></Navigate>;
  }
};

export default Auth;