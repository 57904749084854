import { Suspense } from "react";
import "./LazyLoad.less"

const Loading = () => {
  return <div className="every-loading">
            <img src={require("../assets/img/loading.gif")} alt="" />
        </div>;
};
const LazyLoad = (props) => {
  return <Suspense fallback={<Loading></Loading>}>{props.component}</Suspense>;
};
export default LazyLoad;
